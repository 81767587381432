import React from 'react'
import { Link } from 'react-router-dom'
import './Projects.css'

export default function() {
  return (
    <main>
      <h1 className="page-header">Projects</h1>
      <h3 className="about-subtext-subtle animate-in">Let me show you what I've created</h3>
      
      <div className="projects-container">

        <div className="projects-card">
          <a href="https://offer.search365.ai/over-watch/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Over Watch" src="/ProjOW.jpg"/>
          </a>

          <div>
            <h2>Over Watch</h2>
            <p className="project-key">Key features: <br/> <img src="./Kubernetes.png" alt="K8s" height="16px"/>Kubernetes, <img src="./LogoAZ.png" alt="Azure" height="16px"/>Azure Stack, <img src="./LogoES.png" alt="Elastic" height="16px"/>Elastic, <img src="./LogoMongo.png" alt="MongoDB" height="16px"/>MongoDB</p>
            <p>Not to be confused with the game by Blizzard.</p>
            <p>Deployed and maintained the app on a Linux VM, and subsequently assisted in containerization into K8s.</p>
            <p>
              Over Watch is a name-matching search application that utilizes a third party name translator / indexer to enable smart search matches.
              <br/>(eg. Franklin D Roosevelt = Рузвельт, Франклин Делано = 富兰克林·罗塞费尔特)
            </p>
            <a href="https://offer.search365.ai/over-watch/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Product at Search365</a>
          </div>
        </div>

        <div className="projects-card">
          <a href="https://search365.ai/teams-scout/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Teams App" src="/LogoTeams.png"/>
          </a>

          <div>
            <h2>Teams App</h2>
            <p className="project-key">Key features: <br/> <img src="./Az/az-src.png" alt="Azure Search" height="16px"/>Azure Search, <img src="./LogoTeams.png" alt="Azure" height="16px"/>Teams, <img src="./Az/az-ad.png" alt="Active Directory" height="16px"/>MSAL + Azure Active Directory</p>
            <p>Assisted in creating the React pages and login authentication through Azure Active Directory using the MSAL library.</p>
            <a href="https://search365.ai/teams-scout/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Product at Search365</a>
          </div>
        </div>

        <div className="projects-card">
          <a href="https://www.credly.com/badges/919c0275-d7bb-4e59-91e3-95f713bd2cb0?source=linked_in_profile" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Azure Associate" src="/azure.png"/>
          </a>

          <div>
            <h2>Azure Developer Associate</h2>
            <p className="project-key">Completed: <br/> AZ-204 (Azure Developer) and AZ-900 (Azure Fundamentals)</p>
            <p>Familiarity with various Azure products, particularly Function Apps, Logic Apps, API Management, Virtual Machines, App Services, CosmosDB, Key Vaults, etc.</p>
            <p>
              <img src="./Az/az-fa.png" alt="Function App" class="project-logos"/>
              <img src="./Az/az-la.png" alt="Logic App" class="project-logos"/>
              <img src="./Az/az-apim.png" alt="API Management" class="project-logos"/>
              <img src="./Az/az-vm.png" alt="Virtual Machines" class="project-logos"/>
              <img src="./Az/az-as.png" alt="App Services" class="project-logos"/>
              <img src="./Az/az-kv.png" alt="Key Vault" class="project-logos"/>
            </p>
            <a href="https://www.credly.com/badges/919c0275-d7bb-4e59-91e3-95f713bd2cb0?source=linked_in_profile" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Link to certification</a>
          </div>
        </div>

        <div className="projects-card">
          <a href="https://github.com/benhpoh/Bulldozer-simulator" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Test Driven Bulldozer" src="/Bulldozer.png"/>
          </a>
          <div>
            <h2>Test Driven Bulldozer</h2>
            <p className="project-key">Key features: <br/> <img src="./LogoRuby.png" alt="Ruby" height="16px"/>Ruby, <img src="./LogoTDD.png" alt="TDD" height="16px"/>Test Driven Development, <img src="./LogoBrain.png" alt="Brain" height="16px"/>Thinking Out Loud</p>
            <p>Written as part of a coding exercise, here you get a peek into my thought process in the readme as I built out a CLI program using TDD principles.</p>
            <a href="https://github.com/benhpoh/Bulldozer-simulator" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Bulldozer on GitHub</a>
          </div>
        </div>

        <div className="projects-card">
          <img className="about-circle-images" alt="Portfolio" src="/Ben.jpg"/>
          <div>
            <h2>Portfolio</h2>
            <p className="project-key">Key features: <br/> <img src="./LogoAWS.svg" alt="AWS" height="16px"/>AWS deployment, <img src="./LogoReact.svg" alt="React" height="16px"/>React, <img src="./LogoJS.png" alt="JS" height="16px"/> JavaScript</p>
            <p>Yup, that's me in the picture! This is a fully front end React application, with no server side routing.</p>
            <a href="#"><span className="material-icons">open_in_new</span> Ben Poh on AWS</a>
            <p>(That link is intentionally a dud. This IS the portfolio site!)</p>
          </div>
        </div>

        <div className="projects-card">
          <a href="http://death-star-v2.herokuapp.com/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Death Star" src="/ProjDeathStar.jpg"/>
          </a>

          <div>
            <h2>Death Star 2.0</h2>
            <p className="project-key">Key features: <br/> RESTful APIs, Heroku deployment</p>
            <p>This app was created as part of my introduction to CRUD operations. I've taken the database resource lesson and turned it into a fun web app where you take control of the Death Star to scan for planets, and subsequently blast them apart.</p>
            <p>No plants / animals were hurt as part of this project.</p>
            <a href="http://death-star-v2.herokuapp.com/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Death Star on Heroku</a>
          </div>
        </div>

        <div className="projects-card">
          <a href="http://icq.surge.sh/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="ICQ" src="/ProjICQ.png"/>
          </a>

          <div>
            <h2>ICQ</h2>
            <p className="project-key">Key features: <br/> Web sockets, React static page, Socket IO backend</p>
            <p>Challenging myself to go beyond what was taught in the course, I've attempted to recreate a basic version of ICQ. In 3 days, I was able to achieve MVP with chatroom functionalities. Going with the nostalgic vibe, I couldn't resist skinning it in a retro Windows XP theme.</p>
            <a href="http://icq.surge.sh/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> ICQ on Surge.</a>
            <p>Might I suggest either opening two instances of ICQ, or have someone else also open the link to test the functionality? A chatroom would be lonely if there's just one person in it...</p>
          </div>
        </div>

        <div className="projects-card">
          <a href="https://general-assembly-portal.herokuapp.com/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Academy portal" src="/ProjGA.jpg"/>
          </a>

          <div>
            <h2>Academy Portal</h2>
            <p className="project-key">Key features: <br/> Heroku deployment, Sinatra routing, PostgreSQL database, BCrypt hashing</p>
            <p>Mocked up a student management portal for an educational institute.</p>
            <p>In this app, I produced a web app that accesses a server side database with password access required, to prevent unauthorized server access.</p>
            <a href="https://general-assembly-portal.herokuapp.com/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Academy Portal on Heroku (Enter 'staff' as the password)</a>
          </div>
        </div>

        <div className="projects-card">
          <a href="https://benhpoh.github.io/Connect3/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="Tic Tac Toe" src="/ProjTicTacToe.png"/>
          </a>

          <div>
            <h2>Connect 3</h2>
            <p className="project-key">Key features: <br/> Programmed a bot with pure JavaScript</p>
            <p>Tic Tac Toe dressed up in a sleek skin.</p>
            <p>A simple game, where the challenge lay in coding a bot to play against. Click on the 'Connect' word in the header, and the PC will take control of Blue Player</p>
            <a href="https://benhpoh.github.io/Connect3/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> Connect3 on GitHub pages</a>
          </div>
        </div>

        <div className="projects-card">
          <a href="https://ga-mypet.herokuapp.com/" target="_blank" rel="noreferrer">
            <img className="about-circle-images" alt="MyPet" src="/ProjPet.png"/>
          </a>
          
          <div>
            <h2>MyPet Manager</h2>
            <p className="project-key">Key features: <br/> Real time team collaboration, Git, CRUD</p>
            <p>Take a simple project, insert 4 times the number of developers required, and watch the chaos that follows.</p>
            <p>Jokes aside, this was an exercise in working collaboratively in a team, where the main lessons were in how to effectively split the project up into separate components to avoid programmers coding over each other.</p>
            <a href="https://ga-mypet.herokuapp.com/" target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> MyPet on Heroku</a>
          </div>
        </div>
      </div>

      <br/>

      <footer>
        <Link to="/contact"><span className="material-icons">login</span> Let's chat over some coffee, virtual or otherwise.</Link>
      </footer>

      <br/>

    </main>
  )
}