import React from 'react'
import { Link } from 'react-router-dom'
import './About.css'

const expandSubpage = e => {
  let subpage = e.target.nextSibling
  subpage.classList.toggle("about-hidden")
  subpage.classList.toggle("about-subcategory") 
}
const toggleSaber = (e) => {
  const blade = e.target.nextSibling.nextSibling

  if (blade.classList.contains("lightsaber-blade-off")) {
    document.querySelector("#saber-on").play()
    console.log("Igniting lightsaber");
  } else {
    document.querySelector("#saber-off").play()
    console.log("Retracting lightsaber");
  }
  blade.classList.toggle("lightsaber-blade-off")
  const subpage = e.target.parentElement.nextSibling
  subpage.classList.toggle("about-hidden")
  subpage.classList.toggle("about-subcategory") 
}

export default function(e) {
  return (
    <main className="about-container">
      <h1 className="page-header">Benjamin Poh <span className="about-subtext-subtle">(Just 'Ben', please!)</span></h1>
      <h3 className="about-subtext-subtle animate-in">Touch / click each heading to find out more</h3>

      <section className="about-category">
        <h2 className="about-header animate-in" onClick={expandSubpage}>Animal Lover</h2>

        <div className="about-hidden">
          <img className="about-circle-images" alt="Kikki" src="/Kikki.jpg"/>
          <img className="about-circle-images" alt="Bun" src="/Bun.jpg"/>
          <img className="about-circle-images" alt="Bear" src="/Bear.jpg"/>
          <p>"These are my fur babies. There are many like them, but these ones are mine" - paraphrased from Full Metal Jacket</p>
          <p>I'm a non discriminatory lover of all furry animals!</p>
          <h2 className="about-divider">&emsp;</h2>
        </div>
      </section>

      <section className="about-category">
        <h2 className="about-header animate-in" onClick={expandSubpage}>Software Developer / Cloud Enthusiast</h2>

        <div className="about-hidden">
          <img className="about-circle-images" alt="Azure Associate" src="/azure.png"/>
          <img className="about-circle-images" alt="Azure DevOps" src="/devops.jpg"/>
          <img className="about-circle-images" alt="Kubernetes" src="/Kubernetes.png"/>
          <img className="about-circle-images" alt="General Assembly" src="/ProjGA.jpg"/>
          <img className="about-circle-images" alt="Death Star" src="/ProjDeathStar.jpg"/>
          <p>I've graduated from a Software Engineering bootcamp under General Assembly, here in Melbourne. <a href="https://generalassemb.ly/"  target="_blank" rel="noreferrer"><span className="material-icons">open_in_new</span> General Assembly</a></p>
          <p>Having joined my current company in mid 2020, I'm now able to program in <img src="./LogoTS.png" alt="TS" height="16px"/> TypeScript, <img src="./LogoJS.png" alt="JS" height="16px"/> JavaScript, <img src="./LogoRuby.png" alt="RB" height="16px"/> Ruby, and some <img src="./LogoCS.png" alt="CS" height="16px"/> C#.</p>
          <p>My work revolves around maintaining the company's Azure hosted apps, and deploying products to containers in AKS.</p>
          <p>When you're done here, go to the bottom of the page to see more about what I've created.</p>
          <h2 className="about-divider">&emsp;</h2>
        </div>
      </section>

      <section className="about-category">
        <h2 className="about-header animate-in" onClick={expandSubpage}>Architect</h2>

        <div className="about-hidden">
          <img className="about-circle-images" alt="Apartments" src="/ArchCowper.jpg"/>
          <img className="about-circle-images" alt="Townhouses" src="/ArchHampstead.jpg"/>
          <img className="about-circle-images" alt="Office" src="/ArchJohnston.jpg"/>
          <p>400 apartment units; 157 townhouses; 8,800 square meters of office space. Each of them a standalone project, with project costs exceeding $ 100 million apiece.</p>
          <p>In my previous role, I was an architect who's role was to independantly run multiple projects, and meet stakeholder requirements. I regularly managed the project teams and consultants, whilst mentoring juniors under my care to hone their skillset.</p>
          <h2 className="about-divider">&emsp;</h2>
        </div>
      </section>

      <section className="about-category">
        <h2 className="about-header animate-in" onClick={expandSubpage}>Star Wars Fan</h2>

        <div className="about-hidden">
          <p>Click on the white bar below <span className="about-subtext-subtle">(Warning, there's a slight sound effect...)</span></p>

          <div className="lightsaber">
            <div className="lightsaber-hilt" onClick={(e) => toggleSaber(e)}></div> <div className="lightsaber-blade lightsaber-blade-off"></div>
          </div>

          <div className="about-hidden">
            <p>If you haven't guessed from the lightsaber and the fact that I've got a stormtrooper avatar for my GitHub, yes I am a fan of all things Star Wars!</p>
            <p>Fun fact: My birthday is actually on Star Wars day. Not even kidding about it...</p>
            <h2 className="about-divider">&emsp;</h2>
            <audio src="/Lightsaber.mp3" id="saber-on"></audio>
            <audio src="/RetractSaber.mp3" id="saber-off"></audio>
          </div>
        </div>
      </section>

      <footer>
        <Link to="/projects"><span className="material-icons">login</span> Would you like to see what I've done?</Link>
      </footer>
    </main>
  )
}