import React from 'react'
import './Contact.css'

export default function() {
  return (
    <main>
      <h1 className="page-header">Get In Touch</h1>

      <div className="contact-container animate-in">
        <p>
          Thank you for your time checking out my website.
        </p>
        
        <p>
          If you ever want to chat, my contact details are in my resume. Looking forward to hearing from you!
        </p>

        <div className="contact-subcontainer">
          <a href="https://drive.google.com/file/d/1S6iS5MRKB9isJnw5Csey_4Pv_jQ0Evkw/view" target="_blank" rel="noreferrer"><img src="/LogoResume.png" alt="Resume" className="contact-logo"/> Resume</a>
        </div>
        
        <div className="contact-subcontainer">
          <a href="https://drive.google.com/file/d/1xvawISDhpo350M8ZD1HxKv85nLrdIMiR/view?usp=sharing" target="_blank" rel="noreferrer"><img src="/LogoTick.png" alt="Testimonial" className="contact-logo"/> Testimonial</a>
        </div>
        
        <div className="contact-subcontainer">
          <a href="https://github.com/benhpoh" target="_blank" rel="noreferrer"><img src="/LogoGitHub.png" alt="GitHub" className="contact-logo"/> GitHub</a>
        </div>

        <div className="contact-subcontainer">
          <a href="https://www.linkedin.com/in/benhpoh" target="_blank" rel="noreferrer"><img src="/LogoLinkedIn.png" alt="LinkedIn" className="contact-logo"/> LinkedIn</a>
        </div>
      </div>
    </main>
  )
}