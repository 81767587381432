import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

export default function() {
  const target = window.location.pathname.split("/")[1]

  return (
    <main>
      <h1 className="welcome-header">WELCOME</h1>
      {createFooter(target)}
      <Link to="/about"><span className="material-icons">login</span> Allow me to introduce myself </Link>
    </main>
  )
}

const createFooter = (target) => {
  target = target.toLowerCase()
  switch (target) {
    case "agl":
      return <img className="welcome-banner animate-in" src="/BannerAGL.png" />
    case "afterpay":
      return <img className="welcome-banner animate-in" src="/BannerAfterpay.jpg" />
    case "atlassian":
      return <img className="welcome-banner animate-in" src="/BannerAtlas.png" />
    case "envato":
      return <img className="welcome-banner animate-in" src="/BannerEnvato.png" />
    case "insight":
      return <img className="welcome-banner animate-in" src="/BannerInsight.png" />
    case "iress":
      return <img className="welcome-banner animate-in" src="/BannerIress.png" />
    case "jb":
      return <img className="welcome-banner animate-in" src="/BannerJB.png" />
    case "kogan":
      return <img className="welcome-banner animate-in" src="/BannerKogan.png" />
    case "mcq":
      return <img className="welcome-banner animate-in" src="/BannerMacquarie.png" />
    case "myob":
      return <img className="welcome-banner animate-in" src="/BannerMyob.png" />
    case "rea":
      return <img className="welcome-banner animate-in" src="/BannerREA.png" />
    case "salesforce":
      return <img className="welcome-banner animate-in" src="/BannerSalesforce.png" />
    case "seek":
      return <img className="welcome-banner animate-in" src="/BannerSeek.png" />
    case "slack":
      return <img className="welcome-banner animate-in" src="/BannerSlack.png" />
    case "slalom":
      return <img className="welcome-banner animate-in" src="/BannerSlalom.png" />
    case "xero":
      return <img className="welcome-banner animate-in" src="/BannerXero.png" />
    case "zendesk":
      return <img className="welcome-banner animate-in" src="/BannerZendesk.png" />
    default: 
      return
  }
}