import React from 'react';
import './App.css';
import Home from './pages/Home.js'
import About from './pages/About.js'
import Projects from './pages/Projects.js'
import Contact from './pages/Contact.js'
import { Switch, Route, Link, NavLink } from 'react-router-dom'

class App extends React.Component {
  state = {
    target: ""
  }

  render() {
    return (
      <div className="app-container">
        <nav>
          <Link className="nav-link" to="/">Home</Link>
          <NavLink className="nav-link" to="/about">About me</NavLink>
          <NavLink className="nav-link" to="/projects">What I've done</NavLink>
          <NavLink className="nav-link" to="/contact">Contact</NavLink>
        </nav>

        <div className="body-container">
          <Switch>

            <Route path="/" exact>
              <Home />
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/projects">
              <Projects />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/:target">
              <Home />
            </Route>

            <Route path="/">
              <Home />
            </Route>
            
          </Switch>
        </div>

        <footer class="body-footer">
          <p>&copy; Ben Poh 2021 | <a href="https://github.com/benhpoh" target="_blank" rel="noreferrer">Github</a> | <a href="https://www.benhpoh.com/projects" target="_blank" rel="noreferrer">Portfolio</a></p>
        </footer>
      </div>
    )
  }
}

export default App;
